import mapboxgl from 'mapbox-gl';

if($("body").hasClass("post-type-archive-event")) {

mapboxgl.accessToken = 'pk.eyJ1Ijoid2lsZGlzaCIsImEiOiJjbG9ydjliZWUwcTRpMmpvMWs0aXpoeDd0In0.MxDCE5aOqkHKSFKKKDUB6w';

// number of events to fetch with each request
const FETCH_SIZE = 1000;

const map = new mapboxgl.Map({
  container: 'map',
  style: 'mapbox://styles/mapbox/streets-v12',
  center: [8.16263, 49.17416],
  zoom: 3.90,
});

class EventsMap {
  constructor() {
    this.query = '';
    this.events = [];
    this.filteredEvents = [];
  }

  initializeMap() {
    map.on('load', async () => {
      map.addSource('events', {
        type: 'geojson',
        data: this.formatEvent(this.events),
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      });

      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'events',
        filter: ['has', 'point_count'],
        paint: {
          // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#FFED00',
            100,
            '#FFED00',
            750,
            '#FFED00',
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      });

      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'events',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': ['get', 'point_count_abbreviated'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
      });

      map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'events',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#fff',
          'circle-radius': 12,
          'circle-stroke-width': 4,
          'circle-stroke-color': '#FFED00',
        },
      });

      // inspect a cluster on click
      map.on('click', 'clusters', (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        });
        const clusterId = features[0].properties.cluster_id;
        map.getSource('events').getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          }
        );
      });

      // When a click event occurs on a feature in
      // the unclustered-point layer, open a popup at
      // the location of the feature, with
      // description HTML from its properties.
      map.on('click', 'unclustered-point', (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const name = e.features[0].properties.name;
        const address = e.features[0].properties.address;
        const image = e.features[0].properties.image;
        const dates = e.features[0].properties.dates;
        const topics = e.features[0].properties.topics;
        const categories = e.features[0].properties.categories;
        const url = e.features[0].properties.url;

        map.flyTo({
          center: e.features[0].geometry.coordinates,
          zoom: 13,
        });

        // Ensure that if the map is zoomed out such that
        // multiple copies of the feature are visible, the
        // popup appears over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxgl.Popup({maxWidth: '200px'})
          .setLngLat(coordinates)
          .setHTML(
            `
            <div class="card map-card-event">
                ${image}
                <div class="card-body map-card-event-body">
                  <div class="map-card-event-address">
                    <svg width="14px" height="18px" viewBox="0 0 14 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <title>339B7C13-308C-4986-9C1A-9B952B4118DA</title>
                      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="UI-KIT" transform="translate(-349, -3637)" fill="#464b53">
                          <g id="Group-5" transform="translate(349, 3637)">
                            <path d="M6.75,0 C10.47825,0 13.5,3.02175 13.5,6.75 C13.5,12 6.75,18 6.75,18 C6.75,18 0,12 0,6.75 C0,3.02175 3.02175,0 6.75,0 Z M6.75,3.75 C5.09325,3.75 3.75,5.09325 3.75,6.75 C3.75,8.40675 5.09325,9.75 6.75,9.75 C8.40675,9.75 9.75,8.40675 9.75,6.75 C9.75,5.09325 8.40675,3.75 6.75,3.75 Z" id="Combined-Shape"></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <div>
                     ${address}
                    </div>
                  </div>
                  <div class="map-card-event-open-doors">${name}</div>
                  <div class="map-card-event-dates">
                    ${dates}
                  </div>
                  <div class="map-card-event-categories">
                    ${categories}
                  </div>
                  <div class="map-card-event-tags">
                    <span class="map-card-event-tags-label">Tags:</span> ${topics}
                  </div>
                  <a class="map-card-event-link" href="${url}" target="_blank">
                    <svg width="44px" height="44px" viewBox="0 0 44 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <title>Group 4</title>
                      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Events-map-zoom" transform="translate(-650, -736)">
                          <g id="Group-4" transform="translate(650, 736)">
                            <rect id="Rectangle" fill="#183E79" x="0" y="0" width="44" height="44"></rect>
                            <polyline id="Path-14" stroke="#FFFFFF" points="19.0390625 14.508622 26.5304405 22 19.0390625 29.491378"></polyline>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>
                </div>
              </div>
            `
          ).addTo(map);
      });

      map.on('mouseenter', 'clusters', () => {
        map.getCanvas().style.cursor = 'pointer';
      });
      map.on('mouseleave', 'clusters', () => {
        map.getCanvas().style.cursor = '';
      });
    });
  }

  async initialize() {
    this.events = this.filteredEvents = [];
    this.initializeMap();

    let page = 1;
    // while there are events to query for, update the map
    while(await this.getEvents(page++)) {
      this.updateMap();
    }

    // update the map with the last events added
    this.updateMap();

    if (this.events.length === 0) {
      $('#pills-listing-tab').trigger('click');
    }
  }

  // Updates datas used as a source by the map
  updateMap() {
    let source = map.getSource('events');
    // in case the map isn't initialized for the moment
    if (source) {
      source.setData(this.formatEvent(this.events));
    }
  }

  async getEvents(page = 0) {
    let search = document.location.search;
    let first_separator = (search.length == 0) ? '?' : '&';
    search += first_separator+'size='+FETCH_SIZE+'&page='+page;

    let params = new URLSearchParams(search);
    params.append('action', 'get_events');

    let rep = await fetch(`/wp-admin/admin-ajax.php?${params.toString()}`, { method: 'GET' });
    let response = await rep.json();
    this.events.push(...response.features);

    // We can check if there are events left from the number of results
    // if our size variable is a perfect multiple of the total number of events, we will make a single useless request
    // but it shouldn't cause any problem
    return response.features.length === FETCH_SIZE;
  }

  formatEvent(toFormat) {
    return {type: 'FeatureCollection', features: toFormat};
  }
}

// Add zoom and rotation controls to the map.
map.addControl(new mapboxgl.NavigationControl());

(async function () {
  const event_map = new EventsMap();
  await event_map.initialize();
}(jQuery));

}

